<template>
  <div class="swiperItem" :style="{width:itemW+'px'}" v-on:scroll="swiperScroll">
    <slot></slot>
  </div>
</template>

<script>
  import {windowResize} from "../../libs/base"
  export default {
    name: "swiper-item",
    data() {
      return {
        itemW: 0
      }
    },
    created() {
      var that = this
      windowResize(function () {
        that.$nextTick(() => {
          setTimeout(() => {
            let itemW = parseInt(that.$parent.$el.clientWidth)
            that.itemW = itemW >= 1090 ? itemW : 1090
          },0)
        })
      })
    },
    mounted() {
      let itemW = parseInt(this.$parent.$parent.$el.clientWidth)
      this.itemW = itemW >= 1090 ? itemW : 1090
    },
    methods:{
      swiperScroll(e){
      }
    }
  }
</script>

<style scoped lang="scss">
  .swiperItem {
    float: left;
    overflow: hidden;
    height: 100%;
    min-width: $maxInnerWidth_pc;
  }
</style>
