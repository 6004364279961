/**
 * 名称:swiper组件
 * 作者:王晨煚
 * 时间:2018/9/26/026
 * 描述:
 * 示例:

 */
import ItemComponent from './swiperItem'
import SwiperComponent from './swiper'

const swiper = SwiperComponent
const swiperItem = ItemComponent
const install = function (Vue, globalOptions) {
  if (globalOptions) {
    SwiperComponent.props.globalOptions.default = () => globalOptions
  }
  Vue.component(SwiperComponent.name, SwiperComponent)
  Vue.component(ItemComponent.name, ItemComponent)
}

const VueAwesomeSwiper = { swiper, swiperItem, install }

//export default VueAwesomeSwiper
export { swiper, swiperItem, install }
