<template>
  <div class="swiper-wrap" id="swiper-wrap" :style="{width:swiperOption.width,height:swiperOption.height}">
    <div class="swiper-container" ref="swiperContainer" :style="{width:containerW+'px'}">
      <slot></slot>
    </div>
    <div class="pagination" v-if="swiperOption.pagination && swiperOption.pagination.show">
      <span class="paginationDot" v-for="n in swiperOption.itemsNum"
            :class="{'paginationDotActive':nowIndex===n-1}"
            :style="{
            backgroundColor:nowIndex===n-1?dotActive:'',
            cursor:swiperOption.pagination.canClick === true ? 'pointer':'default'
            }" @click="changePage(n)"></span>
    </div>
    <div class="pageBtn prevBtn" v-if="swiperOption.pageBtn" @click="prevPage"></div>
    <div class="pageBtn nextBtn" v-if="swiperOption.pageBtn" @click="nextPage"></div>
  </div>
</template>

<script>
  import {windowResize,getDevice} from "../../libs/base"

  export default {
    name: "swiper",
    props:[],
    data(){
      return {
        containerW:document.documentElement.clientWidth,
        itemW:document.documentElement.clientWidth,
        pointStart:{x:0,y:0},
        moveX:0,
        pointEnd:{x:0,y:0},
        nowIndex:0,
        flagEnd:true,
        dotActive:'rgba(0,0,0,0.4)',
        device:null,
        swiperOption:{
          width:"100%",
          height:"370px",
          pagination:{
            show:false,
            activeColor:"white"
          },
          pageBtn:false,
          current:0,
          itemsNum:0,
          callback:() => {},
          loop:false,
          loopTime:1000
        },
        stayTime:0,
        timer:null
      }
    },
    created(){
      this.device = getDevice()
      //数据初始化
      //this.windowW = this.$store.state.windowW
      //this.swiperOption.pagination = this.$attrs.options.pagination || false
      this.swiperOption.width = !!this.$attrs.options.width || "100%"
      this.swiperOption.height = this.$attrs.options.height || "370px"
      this.swiperOption.pageBtn = this.$attrs.options.pageBtn || false
      this.swiperOption.current = this.$attrs.options.current || 0
      this.swiperOption.itemsNum = this.$attrs.options.itemsNum || 0
      this.swiperOption.loop = this.$attrs.options.loop || false
      this.swiperOption.loopTime = this.$attrs.options.loopTime || 5000
      if(this.$attrs.options.callback) this.swiperOption.callback = this.$attrs.options.callback
      if(this.swiperOption.pagination){
        //if(this.swiperOption.pagination && this.swiperOption.pagination.activeColor) this.dotActive = this.swiperOption.pagination.activeColor
        this.swiperOption.pagination.show = this.$attrs.options.pagination.show || false
        this.swiperOption.pagination.canClick = this.$attrs.options.pagination.canClick || false
        this.dotActive = this.$attrs.options.pagination.activeColor || "rgba(0,0,0,0.4)"
      }
    },
    mounted(){
      var that = this
      windowResize(function () {
        that.$nextTick(() => {
          //pc端window.clinetWidth > 1000 时我们将最大宽度设置为1000，如果不用setTimeout会获取到设置前的宽度，swiperItem中同理
          setTimeout(() => {
            that.itemW = that.$el.clientWidth
            that.containerW = that.$attrs.options.itemsNum * that.$refs.swiperContainer.children[0].clientWidth
            that.nowIndex = that.swiperOption.current
            that.transform((that.moveX - that.itemW * that.swiperOption.current))
            //this.itemsNum = this.$children.length
          },100)
        })
      })
      this.setLoop()
    },
    watch:{
      "$attrs.options.current":function(val, oldVal) {
        this.addTransition()
        this.transform((this.moveX - this.itemW * val))
        this.nowIndex = val
      }
    },
    methods:{
      setLoop(){
        if(this.swiperOption.loop == true){
          this.timer = setInterval(() => {
            this.nextPage()
          },this.swiperOption.loopTime)
        }
      },
      stopLoop(){
        if(this.swiperOption.loop == true && this.timer){
          clearInterval(this.timer)
        }
      },
      touchstart:function (e) {
        if(this.flagEnd){
          this.stayTime = new Date().getTime();
          this.pointStart = {x:e.targetTouches[0].clientX,y:e.targetTouches[0].clientY}
        }
      },
      touchmove:function (e) {
        //e.stopPropagation()
        if(this.flagEnd){
          var pointMove = {x:e.targetTouches[0].clientX,y:e.targetTouches[0].clientY}
          var moveX = pointMove.x - this.pointStart.x
          var moveY = pointMove.y - this.pointStart.y
          if(Math.abs(moveY) >= Math.abs(moveX)){  //由此判定为用户期望上下滑动滚动内容
            this.flagEnd = false
            moveY = 0
            this.transform(-(this.itemW * this.nowIndex))
          }else{
            e.stopPropagation()
            this.moveX = moveX
            if(this.nowIndex == 0) this.moveX > 100 ? this.moveX = 100 : null
            if(this.nowIndex == this.$children.length - 1) this.moveX < -100 ? this.moveX = -100 : null
            this.transform(this.moveX - this.itemW * this.nowIndex)
          }
        }
      },
      touchend:function (e) {
        if(this.flagEnd){
          this.flagEnd = false
          var oldIndex = this.nowIndex

          var timeStamp = new Date().getTime();
          if(timeStamp - this.stayTime < 300 && Math.abs(this.moveX) == 0){  //说明为点击操作
            return
          }

          this.pointEnd = {x:e.changedTouches[0].clientX,y:e.changedTouches[0].clientY}
          this.addTransition()
          if(this.moveX >= 30){   //向右滑动
            if(this.nowIndex > 0) this.nowIndex --
            else this.nowIndex = 0
          }else if(this.moveX < -30){   //向左滑动
            if(this.nowIndex < this.$children.length-1) this.nowIndex ++
            else this.nowIndex = this.$children.length-1
          }else{
          }
          this.transform(-(this.itemW * this.nowIndex))
          setTimeout(() => {
            this.removeTransition()
            this.moveX = 0
            this.flagEnd = true
          },300)
          if(this.nowIndex != oldIndex){
            this.swiperOption.callback(this.nowIndex)
          }
        }else{
          this.flagEnd = true
        }
      },
      prevPage:function(){
        if(this.flagEnd){
          this.stopLoop()
          this.flagEnd = false
          this.addTransition()
          if(this.nowIndex > 0) this.nowIndex --
          else this.nowIndex = this.$children.length-1
          this.transform(-(this.itemW * this.nowIndex))
          setTimeout(() => {
            this.flagEnd = true
            this.setLoop()
          },300)
        }
      },
      nextPage:function(){
        if(this.flagEnd){
          this.stopLoop()
          this.flagEnd = false
          this.addTransition()
          if(this.nowIndex < this.$children.length-1) this.nowIndex ++
          else this.nowIndex = 0
          this.transform(-(this.itemW * this.nowIndex))
          setTimeout(() => {
            this.flagEnd = true
            this.setLoop()
          },300)
        }
      },
      transform(val){
        try {
          this.$refs.swiperContainer.style.webkitTransform = "translate(" + val + "px,0px)"
          this.$refs.swiperContainer.style.MozTransform = "translate(" + val + "px,0px)"
          this.$refs.swiperContainer.style.msTransform = "translate(" + val + "px,0px)"
          this.$refs.swiperContainer.style.OTransform = "translate(" + val + "px,0px)"
          this.$refs.swiperContainer.style.transform = "translate(" + val + "px,0px)"
        }catch (e) {

        }
      },
      changePage(n){
        if(this.swiperOption.pagination.canClick != true){
          return
        }
        if(n - 1 == this.nowIndex){
          return
        }else{
          this.stopLoop()
          this.addTransition()
          this.nowIndex = n - 1
          this.transform(-(this.itemW * this.nowIndex))
          setTimeout(() => {
            this.setLoop()
          },300)
        }
      },
      addTransition(){
        try {
          this.$refs.swiperContainer.style.webkitTransition = "all .3s"
          this.$refs.swiperContainer.style.MozTransition = "all .3s"
          this.$refs.swiperContainer.style.msTransition = "all .3s"
          this.$refs.swiperContainer.style.OTransition = "all .3s"
          this.$refs.swiperContainer.style.transition = "all .3s"
        }catch (e) {

        }
      },
      removeTransition(){
        try {
          this.$refs.swiperContainer.style.webkitTransition = ""
          this.$refs.swiperContainer.style.MozTransition = ""
          this.$refs.swiperContainer.style.msTransition = ""
          this.$refs.swiperContainer.style.OTransition = ""
          this.$refs.swiperContainer.style.transition = ""
        }catch (e) {

        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .swiper-wrap{
    overflow: hidden;
    position: relative;
  }
  .swiper-container{
    height: 100%;
    will-change: transform; // 创建新的渲染层
    &::after{
      display: block;
      content: "";
      clear: both;
    }
  }
  .pagination{
    position: absolute;
    bottom:50px;
    left: 50%;
    transform: translate(-50%,0);

    .paginationDot{
      height: 8px;
      width: 8px;
      background-color: rgba(0,0,0,0.4);
      border-radius: 8px;
      display: inline-block;
      margin: 0 2px;
      @include transition(width);
    }

    .paginationDotActive{
      width: 40px;
    }
  }
  .pageBtn{
    height: 60px;
    width: 60px;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    cursor: pointer;
    opacity: 0.3;
    background-size:  auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    @include transition(opacity);
    &:hover{
      opacity: 1;
    }
  }
  .prevBtn{
    lelt:0;
    background-image: url("../../assets/image/header/arrow_left.png");
  }
  .nextBtn{
    right: 0;
    background-image: url("../../assets/image/header/arrow_right.png");
  }
</style>
